<template>
    <el-dialog :model-value="show" title="权重配置" width="800px" @close="handleClose" top="5vh">
        <el-form :model="state.form" label-width="160px">
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="餐饮门店数">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_shop_num" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="连锁占比">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_chain_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="店均评论数">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_avg_comment_num" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="平均客单价">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_avg_price" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="门店数增长率">
                        <el-input type="number" placeholder="请输入"
                            v-model.number="state.form.weight_shop_num_inc_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="连锁占比增长率">
                        <el-input type="number" placeholder="请输入"
                            v-model.number="state.form.weight_chain_ratio_inc_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="平均客单价增长率">
                        <el-input type="number" placeholder="请输入"
                            v-model.number="state.form.weight_avg_price_inc_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="新店率">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_new_shop_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="TOP20菜系门店数占比">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_cuisine_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="新菜率">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_new_dish_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="新菜推荐率">
                        <el-input type="number" placeholder="请输入"
                            v-model.number="state.form.weight_new_dish_recommends_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn_bar">
            <el-button @click="onReDefualt">恢复默认值</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </div>
        <div class="table_bar">
            <table>
                <thead>
                    <tr>
                        <th>KPI</th>
                        <th>大类</th>
                        <th>统计值（得分）</th>
                        <th>默认</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="11">客观性指标</td>
                        <td rowspan="4" class="back">规模</td>
                        <td>餐饮门店数-门店数越多得分越高</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td>连锁占比-占比越高得分越高</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>店均平均数-评论数越多得分越高</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>平均客单价-客单价越高得分越高</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td rowspan="3" class="back">成长性</td>
                        <td>门店数增长率-增长越高得分越高</td>
                        <td>16</td>
                    </tr>
                    <tr>
                        <td>连锁占比增长率-增长越高得分越高</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>平均客单价增长率-增长越高得分越高</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td rowspan="4" class="back">创新性</td>
                        <td>新店率-新店率越高得分越高</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>TOP20菜系(third_type)门店数占比-占比越小得分越高</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>新菜率-新菜率越高得分越高</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>新菜推荐率-新菜推荐率越高得分越高</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td colspan="3">合计</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="text_red">注意：合计必须等于100</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </el-dialog>
</template>

<script setup>
import { reactive, watch } from 'vue';
import { getStorage } from '@/utils';
import { ElMessage } from 'element-plus';

const props = defineProps({
    show: { type: Boolean, defualt: false }
})

const emits = defineEmits(['close', 'reDefualt', 'setStorageConfig'])

const state = reactive({
    form: {
        weight_shop_num: null,//餐饮门店数
        weight_chain_ratio: null,//连锁占比
        weight_avg_comment_num: null,//店均评论数
        weight_avg_price: null,//平均客单价
        weight_shop_num_inc_ratio: null,//门店数增长率
        weight_chain_ratio_inc_ratio: null,//连锁占比增长率
        weight_avg_price_inc_ratio: null,//平均客单价增长率
        weight_new_shop_ratio: null,//新店率
        weight_cuisine_ratio: null,//TOP20菜系门店数占比
        weight_new_dish_ratio: null,//新菜率
        weight_new_dish_recommends_ratio: null,//新菜推荐率
    }
})

watch(() => props.show, (newVal) => {
    if (newVal) {
        readSetForm()
    }
})

// 读取缓存配置并给表单赋值
function readSetForm() {
    const weightConfig = getStorage('weightConfigCity');
    state.form = JSON.parse(weightConfig)
}

// 恢复默认值
function onReDefualt() {
    emits('reDefualt')
    readSetForm()
}

// 确定
function submit() {
    let total = 0;
    for (let key in state.form) {
        total += state.form[key]
    }
    if (total !== 100) {
        ElMessage.error('合计必须等于100')
        return
    }
    emits('setStorageConfig', state.form);// 保存到本地缓存
    handleClose()
}

// 关闭监听
function handleClose() {
    emits('close')
}
</script>

<style lang="scss" scoped>
.btn_bar {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    padding-bottom: 30px;
}

.table_bar {
    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ccc;
            text-align: center;
            padding: 5px 0;

            &.back {
                background-color: #f2f2f2;
            }

            &.text_red {
                color: red;
            }
        }

        th {
            background-color: #f2f2f2;
            color: #000;
        }
    }
}

.el-divider--horizontal {
    margin-top: 0;
}
</style>