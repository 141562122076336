import { onBeforeMount, reactive } from "vue";
import { getPotentialDict } from '@/request/api';
import { ElLoading } from 'element-plus';

// 参数选项
export default function paramsListHook({ query, continueQuery }) {
    const state = reactive({
        batchList: [],//批次
        provinceList: [],// 省份
        cuisineList: [],// 餐饮类型-菜系1级
        cuisine1List: [],// 菜系类型-菜系2级
        cuisineTypeList: [],// 菜系-菜系3级
    })
    // 获取批次
    async function getBacth() {
        const loadingInstance = ElLoading.service()
        const { data } = await getPotentialDict({ code: 'batch' })
        state.batchList = data || [];
        continueQuery.batch = state.batchList[0] || '';
        setTimeout(() => { loadingInstance.close() }, 200)
    }
    // 获取省份
    async function getProvince() {
        const { data } = await getPotentialDict({ code: 'province' })
        state.provinceList = data || [];
    }
    // 获取餐饮类型-菜系一级
    async function getCuisine() {
        const { data } = await getPotentialDict({ code: 'cuisine' })
        state.cuisineList = data || []
    }
    // 获取菜系类型-菜系二级
    async function getCuisine1() {
        const { data } = await getPotentialDict({ code: 'cuisine1', cuisine1: query.mainType })
        state.cuisine1List = data || []
    }
    // 获取菜系-菜系三级
    async function getCuisine2() {
        const params = { code: 'cuisine2', cuisine1: query.mainType, cuisine2: query.cuisineType }
        const { data } = await getPotentialDict(params)
        state.cuisineTypeList = data || []
    }
    // 餐饮类型改变
    function changeMainType() {
        state.cuisine1List = [];
        state.cuisineTypeList = [];
        query.cuisineType = [];    // 清空菜系类型
        query.cuisine = [];        // 清空菜系已选
        getCuisine1();             // 获取菜系
        getCuisine2();             // 获取菜系明细
    }
    // 菜系类型改变
    function changeCuisineType() {
        state.cuisineTypeList = [];
        query.cuisine = [];        // 清空菜系已选
        getCuisine2();             // 获取菜系明细
    }

    onBeforeMount(() => {
        getBacth();   // 批次
        getProvince();// 省份
        getCuisine()  // 餐饮类型-菜系1级
        getCuisine1();// 餐饮类型-菜系2级
        getCuisine2();// 餐饮类型-菜系3级
    })

    return { state, changeMainType, changeCuisineType }
}