<template>
  <div class="home">
    <!-- 头部 -->
    <my-header @outLoginFun='outLoginFun' :menuList="state.menuList" v-model:menuActive='state.menuActive'
      @change='menuChange' @weightOpen="weightOpen"></my-header>
    <!-- 数据范围筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="数据范围筛选"></small-title>
      </div>
      <!-- 公共参数 -->
      <div class="search_box">
        <div class="li">
          <h6>省份：</h6>
          <el-select v-model="state.query.province" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.provinceList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>餐饮类型：</h6>
          <el-select v-model="state.query.mainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeMainType">
            <el-option v-for="item in paramsListState.cuisineList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系类型：</h6>
          <el-select v-model="state.query.cuisineType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeCuisineType">
            <el-option v-for="item in paramsListState.cuisine1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系：</h6>
          <el-select v-model="state.query.cuisine" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.cuisineTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 连续性参数 -->
      <div class="search_box" v-if="state.menuActive === 'continue'">
        <div class="li">
          <h6>批次：</h6>
          <el-select v-model="state.continueQuery.batch" class="m-2" placeholder="请选择批次" filterable clearable
            collapse-tags>
            <el-option v-for="item in paramsListState.batchList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>比率：</h6>
          <el-radio-group v-model="state.continueQuery.ratioType">
            <el-radio-button label="环比" />
            <el-radio-button label="同比" />
            <el-radio-button label="前2季度" />
            <el-radio-button label="前3季度" />
          </el-radio-group>
        </div>
      </div>
      <!-- 一次性参数 -->
      <div class="search_box" v-if="state.menuActive === 'one'">
        <div class="li two_select">
          <h6>批次：</h6>
          <el-select v-model="state.oneQuery.fromBatch" class="m-2" placeholder="请选择目标季度" filterable clearable
            collapse-tags>
            <el-option v-for="item in paramsListState.batchList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select v-model="state.oneQuery.toBatch" class="m-2" placeholder="请选择对比季度" filterable clearable
            collapse-tags>
            <el-option v-for="item in paramsListState.batchList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="resetFunc">重置</el-button>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <!-- 表格-连续性 -->
    <div class="row_box" v-if="state.menuActive === 'continue'">
      <div class="tit_box">
        <small-title tit="连续性数据"></small-title>
        <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
            <Right />
          </el-icon>
        </el-button>
      </div>
      <div class="table_box">
        <el-table :data="tableState.tableContinue.tableData" stripe border style="width: 100%" height="600">
          <el-table-column prop="city" label="城市" show-overflow-tooltip fixed="left" align="center" />
          <el-table-column v-for="item in tableState.tableContinue.titleData" :key="item" :label="item" align="center">
            <el-table-column :prop="`${item}_total_score_`" label="总得分" align="center" />
            <el-table-column :prop="`${item}_scope_score_`" label="规模总得分" min-width="100" align="center" />
            <el-table-column :prop="`${item}_growth_score_`" label="成长总得分" min-width="100" align="center" />
            <el-table-column :prop="`${item}_innovate_score_`" label="创新总得分" min-width="100" align="center" />
          </el-table-column>
        </el-table>
        <div class="no_data" v-if="tableState.tableContinue.tableData.length === 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div class="page_box">
          <el-pagination layout="prev, pager, next" background :total="tableState.tableContinue.total"
            :page-size="tableState.tableContinue.query.pageSize"
            v-model:current-page="tableState.tableContinue.query.pageNum" @current-change="getList">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 表格-一次性 -->
    <div class="row_box" v-if="state.menuActive === 'one'">
      <div class="tit_box">
        <small-title tit="一次性数据"></small-title>
        <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
            <Right />
          </el-icon>
        </el-button>
      </div>
      <div class="table_box">
        <el-table :data="tableState.tableOne.tableData" stripe border style="width: 100%"
          :header-cell-style="headerCellStyleHandle" :cell-style="cellStyleHandle" height="600">
          <el-table-column prop="city" label="城市" show-overflow-tooltip fixed="left" />
          <el-table-column :label="oneTableTitle" align="center">
            <el-table-column prop="total_score_" label="总得分" align="center" />
            <el-table-column prop="shop_num" label="餐饮门店数" align="center" />
            <el-table-column prop="chain_ratio" label="连锁占比" align="center" />
            <el-table-column prop="avg_comment_num" label="店均评论数" align="center" />
            <el-table-column prop="avg_price" label="平均客单价" align="center" />
            <el-table-column prop="scope_score_" label="规模总得分" align="center" />
            <el-table-column prop="shop_num_inc_ratio" label="门店数增长率" align="center" />
            <el-table-column prop="chain_ratio_inc_ratio" label="连锁占比增长率" align="center" />
            <el-table-column prop="avg_price_inc_ratio" label="平均客单价增长率" align="center" />
            <el-table-column prop="growth_score_" label="成长总得分" align="center" />
            <el-table-column prop="new_shop_ratio" label="新店率" align="center" />
            <el-table-column prop="cuisine_ratio_score_" label="TOP20菜系(third_type)门店数占比" align="center" />
            <el-table-column prop="new_dish_ratio" label="新菜率" align="center" />
            <el-table-column prop="new_dish_recommends_ratio" label="新菜推荐率" align="center" />
            <el-table-column prop="innovate_score_" label="创新总得分" align="center" />
          </el-table-column>
        </el-table>
        <div class="no_data" v-if="tableState.tableOne.tableData.length === 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div class="page_box">
          <el-pagination layout="prev, pager, next" background :total="tableState.tableOne.total"
            :page-size="tableState.tableOne.query.pageSize" v-model:current-page="tableState.tableOne.query.pageNum"
            @current-change="getList">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
  <!-- 权重配置弹框 -->
  <weight-dialog :show="weightState.show" @close="weightClose" @reDefualt="reDefualt"
    @setStorageConfig='setStorageConfig'></weight-dialog>
</template> 

<script setup>
import MyHeader from "./MyHeader.vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { reactive, computed } from "vue";
import { potentialExport } from "@/request/api";
import outLoginHook from "@/utils/outLoginHook.js";
import paramsListHook from "./paramsListHook.js";
import { ElMessage } from "element-plus";
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";
import { Right } from "@element-plus/icons-vue";
import tableStyleHook from "./tableStyleHook.js";
import menuChangeHook from "./menuChangeHook.js";
import WeightDialog from "./WeightDialog.vue";
import useWeight from "./useWeight.js";
import useTableData from './useTableData';

const state = reactive({
  menuList: [
    { id: 'continue', name: "连续性数据" },
    { id: 'one', name: "一次性数据" }
  ],
  menuActive: 'continue',//顶部菜单选择
  // 公共参数
  query: {
    // 数据范围筛选
    province: [],//省份
    mainType: [], //餐饮类型
    cuisineType: [],//菜系类型
    cuisine: [],//菜系
  },
  // 连续性参数
  continueQuery: {
    batch: '',
    ratioType: '环比'
  },
  // 一次性参数
  oneQuery: {
    fromBatch: '',// 目标
    toBatch: ''// 对比
  }
});

// 一次性数据批次季度显示
const oneTableTitle = computed(() => {
  const { fromBatch, toBatch } = state.oneQuery;
  return (fromBatch ? fromBatch : '目标季度') + ' / ' + (toBatch ? toBatch : '对比季度');
})

// 权重配置
const { state: weightState, open: weightOpen, close: weightClose, reDefualt, setStorageConfig, getWeightConfig } = useWeight()

// 重置
function resetFunc() {
  // 重置条件
  menuChange(state.menuActive);
  // 清空表格
  clearTable()
}

// 获取列表
function getList() {
  switch (state.menuActive) {
    case 'continue':
      getListContinue()
      break;
    case 'one':
      getListOne()
      break;
  }
}

// 表格数据
const { state: tableState, search, getListContinue, getListOne, clearTable } = useTableData(state, getList, getWeightConfig)

// 导出
function exportExcel() {
  let tableData = [];
  switch (state.menuActive) {
    case 'continue':
      tableData = tableState.tableContinue.tableData || [];
      break;
    case 'one':
      tableData = tableState.tableOne.tableData || [];
      break;
  }
  if (tableData.length === 0) {
    ElMessage.error("暂无数据，请搜索");
    return;
  }
  const token = getStorage("token");
  const href = `${baseURL}${potentialExport()}?token=${token}&code=${state.menuActive}`;
  const a = document.createElement("a");
  a.href = href;
  a.download = href;
  a.click();
}

// 顶部菜单切换
const { menuChange } = menuChangeHook(state, clearTable);

// 退出登录
const { outLoginFun } = outLoginHook();

// 条件选项
const { state: paramsListState, changeMainType, changeCuisineType } = paramsListHook(state);

// table样式
const { headerCellStyleHandle, cellStyleHandle } = tableStyleHook();

</script>

<style lang="scss" scoped>
.home {
  padding: 40px;
  padding-top: 80px;

  .row_box {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 10px;

    .tit_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 20px;

      .li {
        margin-right: 20px;
        margin-bottom: 10px;

        h6 {
          font-weight: normal;
          color: #818a9b;
          padding-bottom: 6px;
          font-size: 14px;
        }

        .el-select {
          width: 200px;
        }

        :deep(.el-input__inner) {
          border-radius: 7px;
        }

        .int_box {
          display: flex;
          align-items: center;

          .el-input {
            width: 98px;
          }
        }

        &.two_select {
          .el-select {
            width: 150px;
            margin-right: 15px;
          }
        }
      }
    }

    .btn_box {
      text-align: center;
      padding-top: 10px;
    }

    .table_box {
      position: relative;
      padding-top: 15px;

      .no_data {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 180px;
      }
    }
  }

  .page_box {
    text-align: right;
    padding: 20px 0;
  }
}

:deep(.el-table__empty-text) {
  display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
