import { onBeforeMount, reactive } from 'vue';
import { getStorage, setStorage } from '@/utils';

export default () => {
    // 配置默认值
    const configDefult = {
        weight_shop_num: 15,//餐饮门店数
        weight_chain_ratio: 9,//连锁占比
        weight_avg_comment_num: 5,//店均评论数
        weight_avg_price: 5,//平均客单价
        weight_shop_num_inc_ratio: 16,//门店数增长率
        weight_chain_ratio_inc_ratio: 10,//连锁占比增长率
        weight_avg_price_inc_ratio: 7,//平均客单价增长率
        weight_new_shop_ratio: 12,//新店率
        weight_cuisine_ratio: 8,//TOP20菜系门店数占比
        weight_new_dish_ratio: 8,//新菜率
        weight_new_dish_recommends_ratio: 5,//新菜推荐率
    }
    const state = reactive({
        show: false,
    })

    // 打开弹框
    function open() {
        setDefualt()
        state.show = true;
    }

    // 关闭弹框
    function close() {
        state.show = false;
    }

    // 获取缓存的值，如果不存在，设置默认值
    function setDefualt() {
        const weightConfig = getStorage('weightConfigCity');
        if (!weightConfig) {
            setStorageConfig(configDefult)
        }
    }

    // 将配置表单缓存到本地
    function setStorageConfig(form) {
        setStorage('weightConfigCity', JSON.stringify(form))
    }

    // 恢复默认值
    function reDefualt() {
        setStorageConfig(configDefult)
    }

    // 获取配置对象
    function getWeightConfig() {
        return JSON.parse(getStorage('weightConfigCity'))
    }

    onBeforeMount(() => {
        setDefualt()
    })

    return { state, open, close, reDefualt, getWeightConfig, setStorageConfig }
}