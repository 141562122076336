import { reactive } from "vue";
import { getPotentialContinuity, getPotentialDisposable } from "@/request/api";
import { ElLoading } from 'element-plus';

export default (indexState, getList, getWeightConfig) => {
    const state = reactive({
        // 连续性数据
        tableContinue: {
            query: {
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 一次性数据
        tableOne: {
            query: {
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            tableData: [],
        }
    })

    // 获取列表-连续性数据
    async function getListContinue() {
        const params = { ...indexState.query, ...indexState.continueQuery, ...state.tableContinue.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getPotentialContinuity(params);
        state.tableContinue.total = data?.total || 0;
        state.tableContinue.titleData = data?.title || [];
        state.tableContinue.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-一次性数据
    async function getListOne() {
        const params = { ...indexState.query, ...indexState.oneQuery, ...state.tableOne.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getPotentialDisposable(params);
        state.tableOne.total = data?.total || 0;
        state.tableOne.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 搜索
    function search() {
        switch (indexState.menuActive) {
            case 'continue':
                state.tableContinue.query.pageNum = 1;
                state.tableContinue.total = 0;
                state.tableContinue.titleData = [];
                state.tableContinue.tableData = [];
                break;
            case 'one':
                state.tableOne.query.pageNum = 1;
                state.tableOne.total = 0;
                state.tableOne.tableData = [];
                break;
        }
        getList();
    }

    // 清空所有table/页码重置
    function clearTable() {
        // 连续性
        state.tableContinue.query.pageNum = 1;
        state.tableContinue.total = 0;
        state.tableContinue.titleData = [];
        state.tableContinue.tableData = [];
        // 一次性
        state.tableOne.query.pageNum = 1;
        state.tableOne.total = 0;
        state.tableOne.tableData = [];
    }
    return { state, search, clearTable, getListContinue, getListOne }
}