export default function menuChangeHook(indexState, clearTable) {
    // 菜单改变
    function menuChange(id) {
        indexState.menuActive = id; // 改变当前选中菜单
        // 重置条件
        // 数据范围筛选
        indexState.query.province = [];//省份
        indexState.query.mainType = [];//餐饮类型
        indexState.query.cuisineType = [];//菜系类型
        indexState.query.cuisine = [];//菜系
        //连续性参数
        indexState.continueQuery.ratioType = '环比';//比率
        //一次性参数
        indexState.oneQuery.fromBatch = '';//目标季度
        indexState.oneQuery.toBatch = '';//对比季度
        // 清空表格数据
        clearTable()
    }
    return {
        menuChange
    }
}